<script setup lang="ts">
import type { Api } from '@rialtic/api'

const props = defineProps<{
  connectorId: string
  claim: Api.ProcessedClaimWithInsights
  lineNumber: string
  insightId: string
}>()

const { data: influencing, status } = useApiFetch<Api.InfluencingClaimLine[]>(
  () =>
    `/api/connector/${props.connectorId}/insight/${props.insightId}/influencing-claims`,
  {
    lazy: true,
    server: false,
  },
)

const { sortedInfluencing, setSortState, sortState } = useInfluencingClaims(
  influencing,
  props.claim?.id,
)
</script>

<template>
  <section>
    <h2 class="h2">Influencing Claim Lines</h2>
    <div v-if="sortedInfluencing.length">
      <div class="mb-2 inline-block text-sm text-neutral-700">
        The outcome was influenced by other claim lines.
      </div>
      <div
        class="max-h-87 overflow-auto rounded-lg border-1 border-neutral-300"
      >
        <UiInfluencingClaimLines
          :claim="claim!"
          :hide-end-message="true"
          :influencing-claims="sortedInfluencing"
          :line-number="lineNumber"
          :sort-options="sortState"
          :sticky="false"
          @sort:state="setSortState"
        />
      </div>
    </div>
    <span
      v-else-if="status === 'success'"
      class="inline-block text-sm text-neutral-700"
    >
      The outcome was not influenced by other claim lines.
    </span>
  </section>
</template>
