<script setup lang="ts">
import type { Api } from '@rialtic/api'
import type { AuthHeaders } from '@rialtic/types'

const props = defineProps<{
  policyId: string
  startDate: string
}>()

const { $auth0 } = useNuxtApp()
const { getAccessTokenSilently } = $auth0()

const areDocsViewable = ref(false)
const activeSourceDoc = ref<Api.SourceDocument | null>(null)
const activeSourceDocFullscreen = ref(false)
const reqHeaders = ref<AuthHeaders | null>(null)

const query = computed(() => ({
  start_date: props.startDate,
}))

const { data: policyDetails, pending } = useApiFetch(
  () => `/api/policy/${props.policyId}/details`,
  {
    lazy: true,
    query,
  },
)

const onDownloadFile = async (sourceDoc: Api.SourceDocument) => {
  const blob = await $apiFetch(
    `/api/policy/source-document-file/${sourceDoc.hash}`,
  )

  const downloadLink = document.createElement('a')
  downloadLink.href = window.URL.createObjectURL(blob)
  downloadLink.download = sourceDoc.source_document_name
  downloadLink.style.display = 'none'
  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}

onBeforeMount(async () => {
  const token = await getAccessTokenSilently()

  reqHeaders.value = {
    Authorization: `Bearer ${token}`,
    'auth-provider': 'auth0',
  }
})
</script>

<template>
  <section v-if="policyDetails">
    <div>
      <h2 class="h2">Supporting Documentation</h2>
      <div class="mb-2 inline-block text-sm text-neutral-700">
        The outcome was based on the following documentation.
      </div>
    </div>
    <div
      class="divide-y-1 divide-solid divide-neutral-200 overflow-hidden rounded-lg border border-neutral-200"
    >
      <ReferenceDocument
        v-for="(version, index) in policyDetails?.primary_ref_docs"
        :key="index"
        :document-version="version"
      >
        <template #default="{ source_document }">
          <div v-if="source_document" class="flex gap-2 text-xs">
            <UiButton
              v-if="
                source_document.id &&
                (source_document.format === 'PDF' ||
                  source_document.format === 'CSV')
              "
              class="w-full"
              variant="outlined"
              @click="onDownloadFile(source_document)"
            >
              <div class="flex items-center gap-2">
                Download PDF source file
                <i class="i-ph-download-simple block h-4 w-4" />
              </div>
            </UiButton>
            <UiButton
              v-if="source_document?.internal_url"
              class="w-full"
              variant="outlined"
              @click="activeSourceDoc = source_document"
            >
              <div class="flex items-center gap-2">
                Open source document
                <i class="i-ph-arrows-out block h-4 w-4" />
              </div>
            </UiButton>
          </div>
        </template>
      </ReferenceDocument>
      <ReferenceDocument
        v-for="(version, index) in policyDetails?.supporting_ref_docs"
        :key="index"
        :document-version="version"
      >
        <template #default="{ source_document }">
          <div v-if="source_document" class="flex gap-2">
            <UiButton
              v-if="source_document?.internal_url"
              class="w-full"
              variant="outlined"
              @click="activeSourceDoc = source_document"
            >
              Open source document
            </UiButton>
            <UiButton
              v-if="
                source_document.id &&
                (source_document.format === 'PDF' ||
                  source_document.format === 'CSV')
              "
              class="w-full"
              variant="outlined"
              @click="onDownloadFile(source_document)"
            >
              Download PDF source file
            </UiButton>
          </div>
        </template>
      </ReferenceDocument>
    </div>
    <UiDialog
      v-if="activeSourceDoc"
      :fullscreen="activeSourceDocFullscreen"
      :model-value="true"
      @hide="activeSourceDoc = null"
    >
      <div
        class="flex h-full min-h-[600px] flex-col"
        :class="{
          'max-h-screen': activeSourceDocFullscreen,
          'max-h-[600px]': !activeSourceDocFullscreen,
        }"
      >
        <header
          class="bg-surface-bg flex h-8 w-full justify-end space-x-2 px-2 py-1"
        >
          <button
            class="btn btn-icon"
            @click="activeSourceDocFullscreen = !activeSourceDocFullscreen"
          >
            <div
              v-if="activeSourceDocFullscreen"
              class="i-ph-corners-in-bold"
            />
            <div v-else class="i-ph-frame-corners-bold" />
          </button>
          <button
            class="btn btn-icon"
            aria-label="Close"
            @click="activeSourceDoc = null"
          >
            <div class="i-ph-x-bold" />
          </button>
        </header>
        <LazyUiSourceDocumentViewer
          v-if="activeSourceDoc"
          :name="activeSourceDoc.source_document_name"
          class="h-full min-h-[600px]"
          :class="{ 'h-screen': activeSourceDocFullscreen }"
          :format="activeSourceDoc.format"
          :hash="activeSourceDoc.hash"
          :req-headers="reqHeaders"
        />
      </div>
    </UiDialog>
  </section>
  <UiSkeletonDetails v-else-if="pending" class="w-full pr-0 pl-0" :count="1" />
</template>
