<script setup lang="ts">
import type { Api } from '@rialtic/api'

const props = defineProps<{
  documentVersion: Api.ReferenceDocVersion
}>()

defineSlots<{
  default: (props: { source_document: Api.SourceDocument | null }) => any
}>()

const referenceDoc = computed(() => props.documentVersion.reference_document)
const sourceDoc = computed(() => props.documentVersion.source_document)
</script>

<template>
  <section>
    <UiListItemExpand
      :open="referenceDoc.is_primary"
      data-analytics="claim-line-details"
    >
      <h3 class="text-left text-sm font-medium text-neutral-900">
        {{ referenceDoc.source }}
      </h3>
      <div
        v-if="referenceDoc.is_primary"
        class="subtitle-2 text-secondary-800 bg-secondary-50 py-.75 mt-2 inline-block rounded-full px-3 whitespace-nowrap"
      >
        Primary source
      </div>
      <template #content>
        <div class="space-y-4 p-4 text-xs">
          <div class="flex gap-4">
            <div class="w-37.5">
              <h4 class="text-neutral-900">Source</h4>
            </div>
            <div class="flex-1">
              {{ referenceDoc.source_type_level_1?.name }}
            </div>
          </div>
          <div v-if="documentVersion.policy_excerpt" class="flex gap-4">
            <div class="w-37.5">
              <h4 class="text-neutral-900">Policy Excerpt</h4>
            </div>
            <div class="flex-1">
              <!-- TODO: We need to remove bottom margin from <RichTextBlock /> -->
              <UiRichTextBlock
                class="[&&]:p-0 [&>div]:mb-0"
                :content="documentVersion.policy_excerpt"
                transparent
                view-more-feature
              />
            </div>
          </div>
          <slot v-bind="{ source_document: sourceDoc }" />
        </div>
      </template>
    </UiListItemExpand>
  </section>
</template>
