<script setup lang="ts">
import type { Api } from '@rialtic/api'
import { defu } from 'defu'

const props = defineProps<{
  claim: Api.ProcessedClaimWithInsights
}>()

const route = useRoute<'connectorId-claimId-claimLine'>()

useHead(() => ({
  title: `${route.params.claimId} / ${route.params.claimLine}`,
}))

const lineNumber = computed(() => route.params.claimLine)
const claimLine = computed(
  () =>
    props.claim.claimLines.find(
      (line) => `${line.lineNumber}` === `${lineNumber.value}`,
    ) as Api.ProcessedClaimWithInsights['claimLines'][number],
)
const recommendedInsight = computed(() =>
  getRecommendedInsight(claimLine.value?.insights),
)
const insightBadgeLabel = getInsightBadgeLabel(recommendedInsight.value)
const insightDecision = getInsightDecision(recommendedInsight.value)

const start = computed(() => claimLine.value?.servicedPeriod?.start)

const { $datadog } = useNuxtApp()
watch(
  recommendedInsight,
  (newVal, _oldVal) => {
    const defaultBody = {
      connector_id: route.params.connectorId,
      policy_id: null,
      policy_name: null,
      concept_id: null,
      edit_type: null,
      claim_line_date_of_service_start: claimLine.value?.servicedPeriod.start,
      procedure_code: claimLine.value?.productOrService?.coding[0]?.code,
      carc: null,
      rarc: null,
      insight_id: null,
      rid: props.claim?.rid,
      claim_line_number: lineNumber.value,
    }

    const body = newVal
      ? defu(
          {
            policy_id: newVal.policyId,
            policy_name: newVal.policyName,
            concept_id: newVal.insight.insight.conceptId,
            edit_type: newVal.editType?.edit_type,
            carc: newVal.insight.insight.reasonCodeLevel1,
            rarc: newVal.insight.insight.reasonCodeLevel2,
            insight_id: newVal.insight.insight.id,
          },
          defaultBody,
        )
      : defaultBody

    $apiFetch('/api/analytics/log-insight-view', {
      method: 'POST',
      body,
    }).catch($datadog.addError)
  },
  {
    immediate: true,
  },
)
</script>

<template>
  <main class="flex flex-col p-4 pb-8 lg:pt-6">
    <div class="mb-6 flex items-center space-x-2">
      <h1 class="h1 text-neutral-900">Claim Line {{ lineNumber }}</h1>
      <div
        v-if="insightBadgeLabel"
        class="subtitle-2 inline-flex items-center rounded-full bg-yellow-100 px-2 py-1 text-yellow-800"
      >
        {{ insightBadgeLabel }}
      </div>
    </div>
    <div class="space-y-8">
      <section
        v-if="insightDecision"
        class="space-y-4 border-b-1 border-neutral-200 pb-8 text-xs text-neutral-900"
      >
        <h2 class="h2 text-neutral-900">Decision</h2>
        <div class="flex gap-4">
          <div class="w-37.5">
            <h4 class="font-medium text-neutral-900">Claim Line Status</h4>
          </div>
          <!-- TODO: find a better way to match on type between paapi and console -->
          <div class="flex-1 break-words lowercase first-letter:uppercase">
            {{ insightDecision.status }}
          </div>
        </div>
        <div
          v-if="recommendedInsight && recommendedInsight.editType"
          class="flex gap-4"
        >
          <div class="w-37.5">
            <h4 class="font-medium text-neutral-900">Logic Type</h4>
          </div>
          <div class="flex-1 break-words">
            {{ recommendedInsight.editType.edit_type }}
          </div>
        </div>
        <div class="flex gap-4">
          <div class="w-37.5">
            <h4 class="font-medium text-neutral-900">Reason</h4>
          </div>
          <div class="flex-1 break-words">
            {{ insightDecision.reason }}
          </div>
        </div>
        <div v-if="recommendedInsight" class="flex gap-4">
          <div class="w-37.5">
            <h4 class="font-medium text-neutral-900">Policy Name & ID</h4>
          </div>
          <div class="flex-1">
            <p class="break-words">
              {{ recommendedInsight.policyName || '-' }}
            </p>
            <p class="break-words">
              {{ recommendedInsight.policyId || '-' }}
            </p>
          </div>
        </div>
      </section>

      <template v-if="recommendedInsight">
        <SectionInfluencingClaims
          :claim="claim"
          :connector-id="route.params.connectorId"
          :insight-id="recommendedInsight?.insight.insight.id"
          :line-number="lineNumber"
        />

        <hr class="text-neutral-200" />

        <SectionReferenceDocuments
          :policy-id="recommendedInsight.insight?.insight?.policyId"
          :start-date="start"
        />

        <hr class="text-neutral-200" />
      </template>

      <section class="space-y-6">
        <h2 class="h2">Additional Details</h2>
        <UiClaimLineDetails
          v-if="claimLine"
          :claim="claim"
          :claimLine="claimLine"
        />
        <div class="rounded-lg border border-neutral-200">
          <UiListItemExpand
            roundedBottom
            roundedTop
            data-analytics="claim-line-details"
          >
            <span class="rounded-lg text-sm">Claim Header</span>
            <template #content>
              <div class="px-5 py-4">
                <UiClaimHeader :claim="claim" :showHeader="false" />
              </div>
            </template>
          </UiListItemExpand>
        </div>
      </section>
    </div>
  </main>
</template>
